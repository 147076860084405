import EntityService from "./entity";

class MamberService extends EntityService {

  constructor(name) {
    super(name)
  }
}

export default new MamberService("mamber")
