<template>
  <a-drawer :visible="visible" width="50%"  :maskClosable="false"  @close="handleClose">
    <a-form-model :model="entity" @submit.prevent="handleSave">
      <a-form-model-item
        label="姓名"
        prop="name"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.name" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="公司"
        prop="company"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.company" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="职务"
        prop="duty"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.duty" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="电子邮件"
        prop="email"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.email" allowClear></a-input>
      </a-form-model-item>
      <!-- <a-form-model-item
        label="用户"
        prop="user"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-select
          v-model="entity.user"
          placeholder="请选择"
          :whole="false"
          :dataSource="users"
        ></y-select>
      </a-form-model-item> -->
      <!-- <a-form-model-item
        label="排序"
        prop="sequence"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input-number
          v-model="entity.sequence"
          :defaultValue="1"
          :min="0"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item
        label="状态"
        prop="enabled"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-switch v-model="entity.enabled" />
      </a-form-model-item> -->
      <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-space>
          <a-button htmlType="submit" :loading="loading" type="primary">
            保存
          </a-button>
          <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import ExhibitCategoryService from "@/services/mamber";
import UserService from "@/services/user";
const defaultEntity = {
  properties: {},
};

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      service: ExhibitCategoryService,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      users: [],
    };
  },
  watch: {
    target: {
      handler: function name(value) {
        this.entity = {
          ...defaultEntity,
          ...value,
        };

        if (!this.entity.properties) {
          this.entity.properties = {};
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.entity = JSON.parse(JSON.stringify(defaultEntity));

      this.$emit("close");

      this.$emit("update:visible", false);
      this.$emit("update:loading", false);
    },
    async handleSave() {
      try {
        this.$emit("update:loading", true);
        if (!this.entity.id) {
          await this.service.addEntity(this.entity);
        } else {
          await this.service.updateEntity(this.entity);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(this.entity));

        this.$emit("close");
        this.$emit("update:visible", false);
        this.$emit("update:loading", false);
      }
    },
    async getUsers() {
      const result = await UserService.getEntities();

      this.users = result;
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style>
</style>