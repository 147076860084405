<template>
  <div class="exhibit-category">
    <div class="search-wrapper">
      <a-form-model
        :model="filters"
        :layout="'inline'"
        @submit.prevent="handleSearch"
      >
        <a-form-model-item prop="name" label="姓名">
          <a-input
            v-model="filters.name"
            type="text"
            placeholder="输入搜索"
            allowClear
            @change="
              () => {
                if (filters.name == '') delete filters.name;
              }
            "
          ></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-space>
            <a-button
              icon="search"
              :loading="loading"
              type="primary"
              htmlType="submit"
            >
              搜索
            </a-button>
            <a-button
              icon="redo"
              :loading="loading"
              htmlType="reset"
              @click="handleReset"
            >
              重置
            </a-button>
            <y-export
              type="export"
              :sorter="[{ field: 'createTime', order: 'ASC' }]"
              :service="service"
            >
              全部导出
            </y-export>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <mamber-table
      :loading.sync="loading"
      :filters="filters"
      :sorter="[{ field: 'createTime', order: 'ASC' }]"
    ></mamber-table>
  </div>
</template>

<script>
import MamberService from "../../services/mamber";

import MamberTable from "./table";

const defaultEntity = {
  type: "WECHAT_APPLET",
};

export default {
  components: { MamberTable },
  data() {
    return {
      service: MamberService,
      loading: false,
      filters: JSON.parse(JSON.stringify(defaultEntity)),
    };
  },
  methods: {
    handleSearch() {
      this.filters = JSON.parse(JSON.stringify(this.filters));
    },
    handleReset() {
      this.filters = JSON.parse(JSON.stringify(defaultEntity));
    },
  },
  mounted() {
    this.handleReset();
  },
};
</script>

<style>
</style>